<template>
  <div>
    <el-form
      :model="formData"
      :rules="rules"
      ref="formRef"
      label-width="200px"
      class="detail-form"
      label-suffix="："
    >
      <div class="info-area">
        <div class="title">游戏基本信息</div>
        <el-form-item label="测试时间" prop="testStartTime">
          <el-date-picker
            style="width: 200px"
            size="small"
            type="datetime"
            :disabled="disabled"
            placeholder="选择时间"
            v-model="formData.testStartTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="{
              disabledDate(time) {
                return time.getTime() < Date.now() + 5 * 8.64e7;
              },
            }"
          ></el-date-picker>
          <span style="margin: 0 10px">至</span>
          <el-date-picker
            style="width: 200px"
            size="small"
            type="datetime"
            :disabled="disabled"
            placeholder="选择时间"
            v-model="formData.testEndTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="{
              disabledDate(time) {
                return (
                  time.getTime() < new Date(formData.testStartTime).getTime() ||
                  [0, 6].includes(time.getDay())
                );
              },
            }"
          ></el-date-picker>
          <!-- <el-date-picker
            size="small"
            :disabled="disabled"
            type="datetimerange"
            range-separator="至"
            v-model="formData.betalTime"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="{
              disabledDate(time) {
                return (
                  time.getTime() < Date.now() + 5 * 8.64e7 ||
                  [0, 6].includes(time.getDay())
                );
              },
            }"
          >
          </el-date-picker> -->
          <div class="remark-text">
            请按实际关服时间填写，到期后将自动下架，如需提前下架，请在版本信息中申请下架
          </div>
        </el-form-item>
        <el-form-item label="预计首发时间" prop="predictTime">
          <el-date-picker
            size="small"
            type="datetime"
            :disabled="disabled"
            placeholder="预计首发时间"
            v-model="formData.predictTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="{
              disabledDate(time) {
                return (
                  time.getTime() <
                  new Date(formData.testEndTime).getTime() + 8 * 8.64e7
                );
              },
            }"
          ></el-date-picker>
          <div class="remark-text">
            建议与您的游戏开服时间一致，此时间为本地时间
          </div>
        </el-form-item>
        <el-form-item label="是否删档" prop="delFlag">
          <el-radio-group v-model="formData.delFlag" :disabled="disabled">
            <el-radio
              v-for="item in booleanList"
              :key="item.key"
              :label="item.key"
              >{{ item.value }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="是否计费" prop="costFlag">
          <el-radio-group v-model="formData.costFlag" :disabled="disabled">
            <el-radio
              v-for="item in booleanList"
              :key="item.key"
              :label="item.key"
              >{{ item.value }}</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="返还策略"
          prop="minVal"
          v-if="formData.delFlag && formData.costFlag"
        >
          <el-input
            size="small"
            type="number"
            :disabled="disabled"
            v-model.number="formData.minVal"
            style="width: 200px"
            :min="0"
            :max="1000"
            ><span slot="suffix">%</span></el-input
          >
          <span> ~ </span>
          <el-input
            size="small"
            type="number"
            :disabled="disabled"
            v-model.number="formData.maxVal"
            style="width: 200px"
            :min="formData.minVal"
            :max="1000"
            ><span slot="suffix">%</span></el-input
          >
          <div class="remark-text">
            例：首发时100%-200%返还，要求输入0-1000内整数，后面框的数字需要大于等于前面框的数字。
          </div>
        </el-form-item>
      </div>
      <div class="info-area">
        <div class="title">测试发布范围</div>
        <el-form-item
          label="测试发布范围"
          prop="betaReleaseArea"
          :show-message="false"
          :rules="[{ required: true, validator: checkArea }]"
        >
          <el-cascader
            clearable
            size="small"
            :disabled="true"
            :options="areaList"
            v-model="betaReleaseArea"
            placeholder="请选择测试发布范围"
            :props="areaProps"
          ></el-cascader>
        </el-form-item>
      </div>
      <div class="info-area">
        <div class="title">联系信息</div>
        <el-form-item label="研发公司" prop="company">
          <el-input
            size="small"
            :maxlength="50"
            show-word-limit
            :disabled="disabled"
            v-model="formData.company"
            placeholder="请输入研发公司"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人及联系方式" prop="relation">
          <el-input
            size="small"
            :rows="5"
            type="textarea"
            :maxlength="100"
            show-word-limit
            :disabled="disabled"
            v-model="formData.relation"
            placeholder="请按照以下格式填写联系方式。姓名： QQ： 手机： 邮箱："
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            size="small"
            :rows="5"
            type="textarea"
            :maxlength="200"
            show-word-limit
            :disabled="disabled"
            v-model="formData.remark"
            placeholder="如您有其他补充说明，可在本处填写。"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { booleanList } from "@/common/constant/constant.js";
export default {
  props: {
    closedBetaInfoData: {
      type: Object,
      default() {
        return {};
      },
    },
    operationType: { default: "" },
  },
  created() {
    this.initData(this.closedBetaInfoData);
    this.$store.dispatch("updateAreaListList");
  },
  data() {
    return {
      booleanList,
      formData: {},
      rules: {
        // 用于显示必输的红色星号
        requiredForm: [
          {
            required: true,
            message: "该信息为必输信息",
          },
        ],
        testStartTime: [{ required: true, message: "请选择测试时间" }],
        predictTime: [{ required: true, message: "请输入首发精确时间" }],
        delFlag: [{ required: true, message: "请选择是否删档" }],
        costFlag: [{ required: true, message: "请选择是否计费" }],
        relation: [{ required: true, message: "请输入联系人及联系方式" }],
        minVal: [{ required: true, message: "请输入返还策略" }],
      },
      betaReleaseArea: [],
      areaProps: {
        value: "countryId",
        label: "countryName",
        children: "honorAppCountryList",
        multiple: true,
      },
    };
  },
  computed: {
    ...mapGetters(["areaList"]),
    disabled() {
      return this.operationType === "view" ? true : false;
    },
    releaseArea() {
      const releaseArea = this.$store.state.releaseArea;
      return releaseArea;
    },
  },
  methods: {
    initData(data) {
      //初始改数组数据，否则若父组件为传入，会报错
      const formData = {
        testStartTime: "",
        testEndTime: "",
        requiredForm: 1, // 用于显示必输的红色星号，无业务上的意义
        betaReleaseArea: [],
        delFlag: 0, //	是删档
        costFlag: 1, //	应用是否付费
        ...data,
      };
      this.formData = formData;
      this.getArrReleaseArea(formData.betaReleaseArea); // 发布国家或地区
    },
    checkArea(rule, value, callback) {
      // 校验发布国家或地区
      if (this.betaReleaseArea.length === 0) {
        return callback(new Error("请选择测试发布范围"));
      } else {
        callback();
      }
    },
    // 根据id获取完整的id数组（包含上下级关系）
    async getArrReleaseArea(idList = []) {
      const areaList = await this.$store.dispatch("updateAreaListList");
      const arr = [];
      if (!(idList.length === 0 || areaList.length === 0)) {
        idList.map((key, index) => {
          const parentkey = this.getParentKey(key, areaList);
          arr[index] = [parentkey, key];
        });
      }
      this.betaReleaseArea = arr;
    },
    // 根据arr（包含上下级关系）获取id数组
    getReleaseAreaIds(arrList) {
      const idList = arrList.map((arr) => arr[1]);
      return idList;
    },
    // 根据传入的孩子节点的key获取父节点key
    getParentKey(childrenKey, list = []) {
      let parentItem = "";
      for (let item of list) {
        const childrenList = item[this.areaProps.children];
        if (childrenList && childrenList.length !== 0) {
          const obj = childrenList.find(
            (item1) => item1[this.areaProps.value] === childrenKey
          );
          if (obj) {
            parentItem = item;
            break;
          }
        }
      }
      return parentItem[this.areaProps.value];
    },
    // 暴露给父组件获取表单数据
    getFormData() {
      const data = this.formData;
      const betaReleaseArea = this.getReleaseAreaIds(this.betaReleaseArea); // 将所有已选叶子节点的国家或地区返回
      // data.testStartTime = data.betalTime[0]; //	内测结束时间 格式：yyyy-MM-dd HH:mm:ss
      // data.testEndTime = data.betalTime[1]; //	内测开始时间 格式：yyyy-MM-dd HH:mm:ss
      data.publishCountry = betaReleaseArea; // 	测试发布国家和地区

      return data;
    },
    // 暴露给父组校验表单数据
    validate() {
      let data = false;
      let msg = "";
      this.$refs.formRef.validate((valid) => {
        data = valid;
      });
      if (data) {
        // 校验内测时间和预计首发时间
        const nowTime = new Date().getTime();
        const { testStartTime, testEndTime, predictTime } = this.formData;
        const startTime = new Date(testStartTime).getTime();
        const endTime = new Date(testEndTime).getTime();
        if (startTime >= endTime) {
          msg = "请检查测试时间区间是否有误";
          data = false;
        }
        if (nowTime + 5 * 8.64e7 > startTime) {
          msg = "请提前5天提交内测申请";
          data = false;
        }
        if (endTime + 8 * 8.64e7 > new Date(predictTime).getTime()) {
          msg = "游戏内测必需在首发8天前结束，请修改预计首发时间";
          data = false;
        }
      }
      return { valid: data, msg };
    },
  },
  watch: {
    closedBetaInfoData(data) {
      this.initData(data);
    },
    releaseArea(list) {
      this.betaReleaseArea = list;
    },
  },
};
</script>
<style scoped lang="scss">
$width: 240px;
$height: 135px;
.detail-form {
  .el-input,
  .el-textarea {
    width: 500px;
  }
}
.info-area {
  .title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
    padding-top: 30px;
    border-top: 1px solid #dfdfdf;
  }
  .sub-desc {
    font-size: 14px;
    line-height: 1.5;
    color: #858b90;
    width: 90%;
    margin-bottom: 20px;
  }
}
</style>
