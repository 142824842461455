<template>
  <div>
    <el-form
      :model="formData"
      :rules="rules"
      ref="formRef"
      label-width="200px"
      class="detail-form"
      label-suffix="："
    >
      <div class="info-area">
        <div class="title">预约信息</div>
        <el-form-item label="预约应用包名" prop="packageName">
          <el-input
            size="small"
            :disabled="disabled || disabledPackageName"
            v-model="formData.packageName"
            placeholder="请准确填写预约应用的包名，在预约上架后不能修改"
          ></el-input>
        </el-form-item>
        <el-form-item label="首发精确时间" prop="firstPublishTime">
          <el-date-picker
            size="small"
            type="datetime"
            :disabled="disabled"
            placeholder="首发精确时间"
            v-model="formData.firstPublishTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="new Date().toLocaleTimeString('it-IT')"
            :picker-options="{
              disabledDate(time) {
                return time.getTime() < Date.now() - 8.64e7;
              },
              selectableRange: `${new Date().toLocaleTimeString(
                'it-IT'
              )} - 23:59:59`,
            }"
          ></el-date-picker>
          <div class="remark-text">
            此时间为本地时间，请谨慎填写首发时间，预约审核通过后，若您多次更改首发时间，将会影响预约资源位。首发时间与预约静默安装相关，首发时间如有调整请及时更新
          </div>
        </el-form-item>
        <el-form-item label="联系人及联系方式" prop="contactInfo">
          <el-input
            size="small"
            :rows="5"
            type="textarea"
            :maxlength="100"
            show-word-limit
            :disabled="disabled"
            v-model="formData.contactInfo"
            placeholder="请按照以下格式填写联系方式。姓名： QQ： 手机： 邮箱："
          ></el-input>
        </el-form-item>
        <el-form-item label="评测apk包" prop="apkUrl">
          <el-input
            size="small"
            :disabled="disabled"
            v-model="formData.apkUrl"
            placeholder="请上传评测apk包"
          ></el-input>
          <UploadFile
            :disabled="disabled"
            class="ml10"
            type="apk"
            :expandData="{ appInfoId: formData.id, verify: false }"
            v-model="formData.apkUrl"
          ></UploadFile>
          <div class="remark-text">
            请提供测试包用于预约人工评测，我们会根据内测评级或此处提供的测试包品质，并综合其他因素，决定预约资源位。
          </div>
        </el-form-item>
        <el-form-item label="评测高等级账号" prop="evaluateAccount">
          <el-input
            size="small"
            :maxlength="50"
            show-word-limit
            :disabled="disabled"
            v-model="formData.evaluateAccount"
            placeholder="游戏高等级帐号，评测用"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            size="small"
            :rows="5"
            type="textarea"
            :maxlength="200"
            show-word-limit
            :disabled="disabled"
            v-model="formData.remark"
            placeholder="如您有其他补充说明，可在本处填写。"
          ></el-input>
        </el-form-item>
        <el-form-item label="语言" prop="currentLanguage">
          <el-select
            filterable
            size="small"
            placeholder="请选择语言"
            v-model="formData.currentLanguage"
            @change="handleChangeLanguage"
          >
            <el-option
              v-for="item in localLanguageList"
              :key="item.languageId"
              :label="item.languageName"
              :value="item.languageId"
              >{{
                item.languageId == formData.defaultLanguage
                  ? item.languageName + " - 默认"
                  : item.languageName
              }}</el-option
            >
          </el-select>
          <div class="remark-text">{{ currentLanguageDesc }}</div>
          <div class="remark-text">
            切换语言可维护相应语言的预计上线时间和预约大图
          </div>
        </el-form-item>
        <el-form-item label="预计上线时间" prop="expectPublishTime">
          <el-input
            size="small"
            :maxlength="30"
            show-word-limit
            :disabled="disabled"
            v-model="languageBasicData.expectPublishTime"
            placeholder="例如：2021年1月敬请期待"
          ></el-input>
          <div class="remark-text">{{ currentLanguageDesc }}</div>
          <div class="remark-text">
            用于客户端外显时间，便于您无法确定首发日期时使用。要求该时间与“首发精确时间”保持同一时间段内。
          </div>
        </el-form-item>
        <el-form
          style="padding-top: 15px"
          :model="languageBasicData"
          :rules="lgRules"
          ref="lgFormRef"
          label-width="200px"
          class="detail-form"
          label-suffix="："
        >
          <el-form-item label="预约大图" prop="largeImg">
            <UploadImg
              :limSize="2"
              :limWidth="1280"
              :limHeight="720"
              :disabled="disabled"
              class="upload-horizontal"
              v-model="languageBasicData.largeImg"
              :acceptType="['jpg', 'jpeg', 'png']"
              @success="(file) => handleSuccessUpload(file, 'largeImg')"
            ></UploadImg>
            <div class="remark-text">{{ currentLanguageDesc }}</div>
            <div class="remark-text">
              JPG、PNG格式，图片分辨率为1280*720像素(宽高比为16:9)，单张图片最大为2MB
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class="info-area">
        <div class="title">发布国家或地区</div>
        <el-form-item
          label="发布国家或地区"
          prop="releaseArea"
          :rules="[{ required: true, validator: checkArea }]"
        >
          <el-cascader
            clearable
            size="small"
            :disabled="disabled"
            :options="areaList"
            v-model="releaseArea"
            placeholder="请选择发布国家或地区"
            :props="areaProps"
          ></el-cascader>
        </el-form-item>
      </div>
      <div class="info-area">
        <div class="title">上架</div>
        <el-form-item label="上架时间" prop="effectType">
          <el-radio-group :disabled="disabled" v-model="formData.effectType">
            <el-radio :label="0">立即上线</el-radio>
            <!-- <el-radio :label="1">指定时间</el-radio> -->
          </el-radio-group>
          <br />
          <el-date-picker
            size="small"
            type="datetime"
            :disabled="
              disabled ||
              (formData.releaseType == 2 && formData.releaseStatus == 1003)
            "
            v-model="formData.effectTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-if="formData.effectType === 1"
            placeholder="审核通过后会在您指定的时间上线"
            :default-time="new Date().toLocaleTimeString('it-IT')"
            :picker-options="{
              disabledDate(time) {
                return time.getTime() < Date.now() - 8.64e7;
              },
              selectableRange: `${new Date().toLocaleTimeString(
                'it-IT'
              )} - 23:59:59`,
            }"
          ></el-date-picker>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
import { getAreaList } from "@/common/js/commonApi";
import UploadImg from "@/components/UploadImg.vue";
import UploadFile from "@/components/UploadFile.vue";
export default {
  components: { UploadFile, UploadImg },
  props: {
    appointmentInfoData: {
      type: Object,
      default() {
        return {};
      },
    },
    languageList: {
      type: Array,
      default() {
        return [];
      },
    },
    operationType: { default: "" },
  },
  created() {
    setTimeout(() => {
      this.initData(this.appointmentInfoData);
    }, 1000);
  },
  data() {
    return {
      formData: {},
      rules: {
        // 用于显示必输的红色星号
        requiredForm: [
          {
            required: true,
            message: "该信息为必输信息",
          },
        ],
        packageName: [{ required: true, message: "请输入预约应用包名" }],
        firstPublishTime: [{ required: true, message: "请输入首发精确时间" }],
        contactInfo: [{ required: true, message: "请输入联系人及联系方式" }],
        currentLanguage: [{ required: true, message: "请选择语言" }],
        effectType: [{ required: true, message: "请选择上架时间" }],
      },
      lgRules: {
        largeImg: [{ required: true, message: "请上传预约大图" }],
      },
      releaseArea: [],
      languageBasicData: {}, // 可多语言的数据
      areaProps: {
        value: "countryId",
        label: "countryName",
        children: "honorAppCountryList",
        multiple: true,
      },
      odlLanguage: "", // 保存切换语言时，旧语言的信息
      disabledPackageName: false, // 包名是否可修改
      areaList: [],
    };
  },
  computed: {
    disabled() {
      return this.operationType === "view" ? true : false;
    },
    currentLanguageDesc() {
      const currentLanguage = this.formData.currentLanguage;
      let desc =
        (this.languageList.find((i) => i.languageId === currentLanguage) || {})
          .languageName || "";
      if (currentLanguage === this.formData.defaultLanguage) {
        desc += " - 默认";
      }
      return desc;
    },
    localLanguage() {
      return this.$store.state.localLanguageIdList;
    },
    localLanguageList() {
      const list = this.languageList.filter((i) =>
        this.localLanguage.includes(i.languageId)
      );
      return list;
    },
  },
  methods: {
    handleSuccessUpload(file, name) {
      this.$refs.lgFormRef.clearValidate(name);
    },
    handleChangeLanguage(lg) {
      const oldLang = this.odlLanguage;
      // oldLang不存在时，不需要进行任何操作
      if (oldLang || oldLang == 0) {
        this.odlLanguage = lg; // 更新odlLanguage的信息
        this.setLanguageInfo(oldLang, this.languageBasicData); // 保存当前语言的信息
        const currentData = this.getLanguageInfo(lg); // 获取切换后的语言信息
        if (currentData) {
          // 若切换后语言的基础信息已存在，将该信息显示出来(深拷贝)
          this.languageBasicData = JSON.parse(JSON.stringify(currentData));
        } else {
          // 若切换后的语言不存在信息（新增语言信息），则显示默认语言信息
          // 目前不需要显示默认语言信息
          // const defaultData = this.getLanguageInfo(
          //   this.formData.defaultLanguage
          // );
          // this.languageBasicData = JSON.parse(JSON.stringify(defaultData));
          this.languageBasicData = {};
        }
      }
    },

    // 后端请求
    async getAreaList() {
      const res = await getAreaList();
      let list = [];
      if (res && res.code == 0) {
        list = res.data || [];
        this.areaList = list;
      }
      return list;
    },
    // 其他
    initData(data) {
      //初始改数组数据，否则若父组件为传入，会报错
      const formData = {
        requiredForm: 1, // 用于显示必输的红色星号，无业务上的意义
        packageName: "",
        defaultLanguage: "", // 默认语言,不能给一个具体的数据，不然会触发watch，造成bug
        releaseArea: [],
        languagDescFroms: [],
        effectType: 0,
        ...data,
      };
      formData.currentLanguage = formData.defaultLanguage; // 默认语言赋值给当前编辑的语言
      this.odlLanguage = formData.currentLanguage; // 填充odlLanguage
      this.formData = formData; // 填充formData
      const languageBasicData = this.getLanguageInfo(
        formData.currentLanguage
      ) || { largeImg: "" }; // 当前语言的基础数据
      this.languageBasicData = languageBasicData;
      this.getArrReleaseArea(formData.releaseArea); // 发布国家或地区
      this.disabledPackageName =
        (data.packageName && data.releaseType !== 2) ||
        data.releaseStatus != 1001; // 其他上架类型转预约且已存在包名，包名不可修改;已上架过的应用，包名也不可修改
    },
    checkArea(rule, value, callback) {
      // 校验发布国家或地区
      if (this.releaseArea.length === 0) {
        return callback(new Error("请选择发布国家或地区"));
      } else {
        callback();
      }
    },
    // 根据id获取完整的id数组（包含上下级关系）
    async getArrReleaseArea(idList = []) {
      const areaList = await this.getAreaList();
      const arr = [];
      if (!(idList.length === 0 || areaList.length === 0)) {
        idList.map((key, index) => {
          const parentkey = this.getParentKey(key, areaList);
          arr[index] = [parentkey, key];
        });
      }
      this.releaseArea = arr;
    },
    // 根据arr（包含上下级关系）获取id数组
    getReleaseAreaIds(arrList) {
      const idList = arrList.map((arr) => arr[1]);
      return idList;
    },
    // 根据传入的孩子节点的key获取父节点key
    getParentKey(childrenKey, list = []) {
      let parentItem = "";
      for (let item of list) {
        const childrenList = item[this.areaProps.children];
        if (childrenList && childrenList.length !== 0) {
          const obj = childrenList.find(
            (item1) => item1[this.areaProps.value] === childrenKey
          );
          if (obj) {
            if (
              this.formData.releaseType == 2 &&
              this.formData.releaseStatus == 1003
            ) {
              // this.formData.releaseType 预约 2
              // this.formData.releaseStatus 上架 1003
              //数据不允许删除
              item.disabled = true;
            } else {
              item.disabled = false;
            }
            parentItem = item;
            break;
          }
        }
      }
      return parentItem[this.areaProps.value];
    },
    // 获取可多语言的信息数据
    getLanguageInfo(lg) {
      return this.formData.languagDescFroms.find((i) => i.language === lg);
    },
    // 设置可多语言的信息数据
    setLanguageInfo(lg, lgData) {
      const data = JSON.parse(JSON.stringify(lgData)); //深拷贝
      data.language = lg; //更改数据里的语言，以防lgData里残留其他语言数据
      const index = this.formData.languagDescFroms.findIndex(
        (i) => i.language === lg
      );
      if (index !== -1) {
        // 原本存在该语言的数据则修改
        this.formData.languagDescFroms.splice(index, 1, data);
      } else {
        // 原本不存在该语言的数据则添加
        this.formData.languagDescFroms.push(data);
      }
    },
    getLanguageDesc(lgId) {
      return (
        (this.languageList.find((i) => i.languageId === lgId) || {})
          .languageName || ""
      );
    },
    // 暴露给父组件获取表单数据
    getFormData() {
      const data = this.formData;
      if (data.effectType == 0) {
        data.effectTime = "";
      }
      const releaseArea = this.getReleaseAreaIds(this.releaseArea); // 将所有已选叶子节点的国家或地区返回
      data.countryList = releaseArea;
      this.setLanguageInfo(data.currentLanguage, this.languageBasicData); // 保存当前语言的信息
      return data;
    },
    // 暴露给父组校验表单数据
    validate() {
      let data = false;
      let lgData = false;
      let msg = "";
      this.$refs.lgFormRef.validate((valid) => {
        lgData = valid;
      });
      this.$refs.formRef.validate((valid) => {
        data = valid;
      });
      if (data && lgData) {
        // 检查多语言信息是否完整
        this.setLanguageInfo(
          this.formData.currentLanguage,
          this.languageBasicData
        ); // 保存当前语言的信息
        for (let lg of this.formData.languagDescFroms) {
          if (this.localLanguage.includes(lg.language)) {
            if (!lg.largeImg) {
              lgData = false;
              const lgDesc = this.getLanguageDesc(lg.language);
              msg = `请上传${lgDesc}的预约大图；`;
              break;
            }
          }
        }
        // 检查是否已维护所有已经选择的语言的预约大图
        const languageInfosIds = this.formData.languagDescFroms.map(
          (i) => i.language
        );
        for (let lgId of this.localLanguage) {
          if (!languageInfosIds.includes(lgId)) {
            lgData = false;
            const lgDesc = this.getLanguageDesc(lgId);
            msg = `请上传${lgDesc}的预约大图`;
            break;
          }
        }
      }
      return { valid: data && lgData, msg };
    },
  },
  watch: {
    appointmentInfoData(data) {
      this.initData(data);
    },
  },
};
</script>
<style scoped lang="scss">
$width: 240px;
$height: 135px;
.detail-form {
  .el-input,
  .el-textarea {
    width: 500px;
  }
}
.info-area {
  .title {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 8px;
    padding-top: 30px;
    border-top: 1px solid #dfdfdf;
  }
  .sub-desc {
    font-size: 14px;
    line-height: 1.5;
    color: #858b90;
    width: 90%;
    margin-bottom: 20px;
  }
}

.upload-horizontal {
  width: 240px;
  height: 135px;
  margin-right: 10px;
}
</style>
